import React, { Component } from 'react';
import './App.scss';
import one from './1.mp3';
import two from './2.mp3';
import three from './3.mp3';
import four from './4.mp3';
import five from './5.mp3';
import six from './6.mp3';
import seven from './7.mp3';
import eight from './8.mp3';
import nine from './9.mp3';
import ten from './10.mp3';
import eleven from './11.mp3';

import cory_1 from './cory_1.png';
import cory_2 from './cory_2.png';
import _ from 'underscore';

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            is_playing: false,
            has_won_before: false,
            cursor: '',
            target_position: {
                x: 0,
                y: 0
            },
            distance_interval_array: [],
            level: 1,
            img_style: {},
            is_animating: false,
            expert_mode: false,
            is_showing_second_image: false,
            game_start_date: null,
            global_count: null,
            global_cream_count: null,
            current_cream_count: 0
        };

        this.buffer_array = [];
        this.audioLoop = null;

        // create the audio context, check for supported browser
        if (!window.AudioContext) {
            if (!window.webkitAudioContext) {
                alert('This website does not support your browser.');
                return;
            }
            window.AudioContext = window.webkitAudioContext;
        }
        this.audioContext = new AudioContext();

        // load audio files
        this.setAudioFile(one, 0)
        this.setAudioFile(two, 1)
        this.setAudioFile(three, 2)
        this.setAudioFile(four, 3)
        this.setAudioFile(five, 4)
        this.setAudioFile(six, 5)
        this.setAudioFile(seven, 6)
        this.setAudioFile(eight, 7)
        this.setAudioFile(nine, 8)
        this.setAudioFile(ten, 9)
        this.setAudioFile(eleven, 10)

        // throttle calls to updateAudioSource
        // 574ms is the duration of the audio files
        this.updateAudioSource = _.throttle(this.updateAudioSource, 574);

    }

    componentDidMount() {
        // var xhr = new XMLHttpRequest();
        // xhr.open("GET", '/getGlobalCounter', true);
        // xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        // xhr.onreadystatechange = () => {
        //     if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        //         const res = JSON.parse(xhr.response);
        //         this.setState({
        //             global_count: this.numberWithCommas(res.global_count),
        //             global_cream_count: this.numberWithCommas(res.global_cream_count)
        //         });
        //     }
        // }
        // xhr.send();

        // window.onbeforeunload = () => {
        //     this.updateGlobalCounter();
        // }
    }

    setAudioFile(url, i) {
        var request = new XMLHttpRequest();
        request.open('GET', url, true);
        request.responseType = 'arraybuffer';
        request.onload = () => {
            this.audioContext.decodeAudioData(request.response, buffer => {
                this.buffer_array[i] = buffer;
                
            }, function(e) {
                console.log('Audio error! ', e);
            });
        }
        request.send();
    }

    getDistance(x1, y1, x2, y2) {
        const distance = 
            Math.ceil(    
                Math.sqrt(
                    Math.pow(x2 - x1, 2) + 
                    Math.pow(y2 - y1, 2)
                )
            );

        return distance;
    }

    getRandomTargetPosition() {
        const position = { };
        position.x = Math.floor(Math.random() * Math.floor(window.innerWidth));
        position.y = Math.floor(Math.random() * Math.floor(window.innerHeight));
        return position;
    }

    getTargetPositionQuadrant(target_position) {
        const y_axis = window.innerHeight / 2;
        const x_axis = window.innerWidth / 2;

        if(target_position.x >= x_axis) {
            if(target_position.y >= y_axis) {
                return 3;
            } else {
                return 2;
            }
        } else {
            if(target_position.y >= y_axis) {
                return 4;
            } else {
                return 1;
            }
        }
    }

    getDistanceIntervalArray(target_position) {
        let furthest_distance = 0;
        let result_array = [];
        switch(this.getTargetPositionQuadrant(target_position)) {
            case 1:
                furthest_distance = this.getDistance(
                    window.innerWidth,
                    window.innerHeight,
                    target_position.x,
                    target_position.y
                );
                break;
            case 2:
                furthest_distance = this.getDistance(
                    0,
                    window.innerHeight,
                    target_position.x,
                    target_position.y
                );
                break;
            case 3:
                furthest_distance = this.getDistance(
                    0,
                    0,
                    target_position.x,
                    target_position.y
                );
                break;
            case 4:
                furthest_distance = this.getDistance(
                    window.innerWidth,
                    0,
                    target_position.x,
                    target_position.y
                );
                break;
            default:
                break;
        }

        for(let i = 0; i < 9; i++) {
            result_array[i] = (furthest_distance / 9) * i;
        }
        
        return result_array;
    }

    isOnTarget(x, y) {
        if (x > this.state.target_position.x - 15 && 
            x < this.state.target_position.x + 15 &&
            y > this.state.target_position.y - 15 &&
            y < this.state.target_position.y + 15) {
                return true
        } else {
            return false;
        }
    }

    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    getImageTranslateStyle(target_position) {
        let x, y;
        const max_x = window.innerWidth / 2;
        const max_y = window.innerHeight / 2;

        x = target_position.x - max_x
        y = target_position.y - max_y
       
        return `translate(${x}px, ${y}px)` 
    }

    handleMouseMove = (e) => {
        if(this.state.is_playing) {
            let cursor_style = '';
            let level = 1;

            if(this.isOnTarget(e.clientX, e.clientY) && !this.state.expert_mode) cursor_style = 'pointer';

            const distance = this.getDistance(
                this.state.target_position.x,
                this.state.target_position.y,
                e.clientX,
                e.clientY
            )

            if(distance >= 0 && distance < 25) {
                level = 10
            } else if(distance >= 25 && distance < this.state.distance_interval_array[1]) {
                level = 9;
            } else if(distance >= this.state.distance_interval_array[1] && distance < this.state.distance_interval_array[2]) {
                level = 8;
            } else if(distance >= this.state.distance_interval_array[2] && distance < this.state.distance_interval_array[3]) {
                level = 7;
            } else if(distance >= this.state.distance_interval_array[3] && distance < this.state.distance_interval_array[4]) {
                level = 6;
            } else if(distance >= this.state.distance_interval_array[4] && distance < this.state.distance_interval_array[5]) {
                level = 5;
            } else if(distance >= this.state.distance_interval_array[5] && distance < this.state.distance_interval_array[6]) {
                level = 4;
            } else if(distance >= this.state.distance_interval_array[6] && distance < this.state.distance_interval_array[7]) {
                level = 3;
            } else if(distance >= this.state.distance_interval_array[7] && distance < this.state.distance_interval_array[8]) {
                level = 2;
            } else if(distance >= this.state.distance_interval_array[8]) {
                level = 1;
            }

            this.setState({
                cursor: cursor_style,
                level: level
            });
        }
    }

    updateAudioSource(level) {
        if(this.state.is_playing) {
            this.source.stop();
            this.source = this.audioContext.createBufferSource();
            this.source.buffer = this.buffer_array[level - 1];
            this.source.connect(this.audioContext.destination);
            this.source.loop = true;
            this.source.start();   
        }   
    }

    handleClick = (e) => {
        if(this.isOnTarget(e.clientX, e.clientY) && this.state.is_playing) {
            this.source.stop();
            clearInterval(this.audioLoop);
            // this.updateGlobalCounter();
            this.setState({
                is_playing: false,
                has_won_before: true,
                cursor: '',
                is_animating: true,
            }, () => {
                setTimeout(() => {
                    this.setState({
                        img_style: {
                            transform: `translate(0px, 0px) scale(3)` 
                        }
                    }, () => {
                        setTimeout(() => {
                            this.source = this.audioContext.createBufferSource();
                            this.source.buffer = this.buffer_array[10];
                            this.source.connect(this.audioContext.destination);
                            this.source.loop = false;
                            this.source.start();
                            this.setState({
                                is_showing_second_image: true
                            }, () => {
                                setTimeout(() => {     
                                    this.setState({
                                        is_showing_second_image: false
                                    }, () => {
                                        setTimeout(() => {
                                            this.setState({
                                                is_animating: false
                                            }, () => {
                                            });
                                        }, 1000);
                                    });
                                }, 300);
                            });
                        }, 1300);
                    })
                }, 10);
            });        
        }
    }

    updateGlobalCounter = () => {
        var xhr = new XMLHttpRequest();
        xhr.open("POST", '/updateGlobalCounter', true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhr.onreadystatechange = () => { // Call a function when the state changes.
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                // const res = JSON.parse(xhr.response);
                this.setState({
                    current_cream_count: 0
                });
            }
        }
        xhr.send(`num_creams=${this.state.current_cream_count}`);
    }

    handleCheckboxClick = (e) => {
      this.setState({
        expert_mode: e.target.checked
      })
    }

    handleStartGame = () => {
        const target_position = this.getRandomTargetPosition();
        // set the target to the upper left corner for debugging
        // const target_position = {x: 0, y: 0}
        const distance_interval_array = this.getDistanceIntervalArray(target_position);
        const img_styles = this.getImageTranslateStyle(target_position);

        this.setState({
            is_playing: true,
            target_position: target_position,
            distance_interval_array: distance_interval_array,
            img_style: {
                transform: img_styles
            },
            game_start_date: new Date()
        }, () => {
            this.source = this.audioContext.createBufferSource();
            this.source.buffer = this.buffer_array[0];
            this.source.connect(this.audioContext.destination);
            this.source.loop = true;
            this.source.start();

            this.audioLoop = setInterval(() => {
                this.updateAudioSource(this.state.level);
                //update the counter
                this.setState({
                    // global_cream_count:  this.numberWithCommas(parseInt(this.state.global_cream_count.replace(',', "")) + 1),
                    current_cream_count:  this.state.current_cream_count + 1
                });
            }, 574);
        })
    }
    
    render() {
        return (
            <div id="game" onClick={this.handleClick} onMouseMove={this.handleMouseMove} className={this.state.cursor}>
                <div className="counter_container">
                    {/* <p>Cory's found globally: <span>{this.state.global_count}</span></p> */}
                    {/* <p>Global cream counter: <span>{this.state.global_cream_count}</span></p> */}
                </div>
                {!this.state.is_playing && !this.state.has_won_before &&
                    <div className='modal instructions'>
                        <h1>Find the Invisible Cory</h1>
                        <p>Move your cursor around to find Cory. He's hiding, the closer you get the louder he creams.</p>
                        <p className='italic'>Make sure you turn your audio on before playing.</p>
                        <button onClick={this.handleStartGame}>Start Game</button>
                        <p><a href='https://www.youtube.com/watch?v=NVFubJKDfeU'>Who's Cory?</a></p>
                    </div>
                }
                {!this.state.is_playing && this.state.has_won_before && !this.state.is_animating &&
                    <div className='modal'>
                        <h1>Congratulations, you found Cory!</h1>
                        <div>
                            <input
                            name="expert_mode"
                            type="checkbox"
                            checked={this.state.expert_mode}
                            onChange={this.handleCheckboxClick} />
                            <p>Expert Mode (your cursor won’t change when you hover over Cory)</p>
                        </div>
                        <button onClick={this.handleStartGame}>Start Game</button>
                    </div>
                }
                {this.state.is_animating &&
                    <div className='img_container' style={this.state.img_style}>
                        {this.state.is_showing_second_image && <img src={cory_2} alt='cory'></img>}
                        <img src={cory_1} alt='cory'></img>
                    </div>
                }

                <div className='images'>
                    <img src={cory_2} alt='cory'></img>
                    <img src={cory_1} alt='cory'></img>
                </div>
            </div>
        );
    }
}

export default App;